import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Course } from './course.model';
import { CourseStore, CourseState } from './course.store';

@Injectable({ providedIn: 'root' })
export class CourseQuery extends QueryEntity<CourseState> {
  find$ = this.select('find');
  params$ = this.select('params');

  constructor(protected store: CourseStore) {
    super(store);
  }

  get getFindData(): Course {
    return this.store.getValue().find.data;
  }

  selectFindData$ = this.select((state) => {
    return state.find ? state.find.data : null;
  });

  selectDatatableListData$ = this.select((state) => {
    return state.datatableList.data;
  });

  selectDatatableListLoading$ = this.select((state) => {
    return state.datatableList.isLoading;
  });

  selectDatatableListTotalPage$ = this.select((state) => {
    return state.datatableList.totalPage;
  });

  selectDatatableListTotal$ = this.select((state) => {
    return state.datatableList.total;
  });

  selectParamsCourseCategoryIds$ = this.select((state) => {
    return state.params.course_category_ids;
  });

  selectParamsOrder$ = this.select((state) => {
    return state.params.order;
  });

  selectParamsPage$ = this.select((state) => {
    return state.params.page;
  });
}
