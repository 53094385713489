import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CourseStore } from './course.store';
import { Course, CourseDatatable, CourseParams } from './course.model';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CourseReview } from '../../course-review/state/course-review.model';
import * as queryString from 'qs';

@Injectable({ providedIn: 'root' })
export class CourseService {
  constructor(private courseStore: CourseStore, private http: HttpClient) { }

  find(id: number): Observable<Course> {
    return this.http.get<Course>(`/courses/public/${id}`).pipe(
      tap((res) => {
        this.courseStore.update({ find: { data: res, error: null } });
      })
    );
  }

  loadCourse(params: CourseParams): Observable<CourseDatatable> {
    this.courseStore.update((state) => ({
      ...state,
      datatableList: {
        ...state.datatableList,
        isLoading: true,
        error: null,
      },
    }));

    return this.http.get<CourseDatatable>(`/courses?datatable=true&${queryString.stringify(params)}`).pipe(
      tap((res) => {
        this.courseStore.loadCourseSuccess(res);
      })
    );
  }

  clearParams(): void {
    this.courseStore.clearParams();
  }

  clearDatatableList(): void {
    this.courseStore.clearDatatableList();
  }

  pageNext(): void {
    this.courseStore.pageNext();
  }

  pagePrevious(): void {
    this.courseStore.pagePrevious();
  }

  pageSelected(page: number): void {
    this.courseStore.pageSelected(page);
  }

  orderbyChange(): void {
    this.courseStore.changeOrderby();
  }

  changeParams(params: CourseParams): void {
    this.courseStore.update((state) => ({
      ...state,
      params: {
        ...state.params,
        ...params,
        page: 1,
      },
    }));
  }

  // tslint:disable-next-line: variable-name
  paramsCourseCaterory(course_category_id: number): void {
    this.courseStore.update((state) => ({
      ...state,
      params: {
        ...state.params,
        course_category_id,
      },
    }));
  }

  review(payload: CourseReview): Observable<CourseReview> {
    return this.http.post<CourseReview>('/course_reviews', payload);
  }
}
