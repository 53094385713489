import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CourseReviewStore } from './course-review.store';
import { CourseReview, CourseReviewParams } from './course-review.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as queryString from 'qs';
import { resetStores } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class CourseReviewService {
  constructor(private courseReviewStore: CourseReviewStore, private http: HttpClient) {}

  get(params: CourseReviewParams): Observable<CourseReview[]> {
    this.courseReviewStore.setLoading(true);
    return this.http.get<CourseReview[]>(`/course_reviews?${queryString.stringify(params)}`).pipe(
      tap((entities) => {
        this.courseReviewStore.setLoading(false);
        this.courseReviewStore.set(entities);
      })
    );
  }

  reset(): void {
    this.courseReviewStore.set([]);
  }

  create(payload: CourseReview): Observable<CourseReview> {
    return this.http.post<CourseReview>('/course_reviews', payload).pipe(
      tap((response) => {
        this.courseReviewStore.add(response);
      })
    );
  }
}
