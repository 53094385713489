import { Injectable } from '@angular/core';
import { CourseReview } from './course-review.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CourseReviewState extends EntityState<CourseReview> {
  isLoadMore: boolean;
}

export function createInitialState(): CourseReviewState {
  return {
    isLoadMore: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'course-review', resettable: true })
export class CourseReviewStore extends EntityStore<CourseReviewState> {
  constructor() {
    super(createInitialState());
  }
}
