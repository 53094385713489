export interface CourseCategoryMenu {
  id: number;
  name: string;
  alt?: string;
  icon: string;
  router: string;
  description: string;
  isMobile?: boolean;
  iconMobile?: string;
}

export const CourseCategoryMenuList: CourseCategoryMenu[] = [
  {
    id: 1,
    name: 'วิทยาศาสตร์',
    icon: 'assets/images/icon/mathematics.svg',
    iconMobile: 'assets/images/icon/mathematics.svg',
    alt: 'mathematics',
    router: '/course/course-category',
    description: 'ความรู้เกี่ยวกับสิ่งต่างๆในธรรมชาติทั้งที่มีชีวิตและไม่มีชีวิต',
  },
  {
    id: 2,
    name: 'คณิตศาสตร์',
    icon: 'assets/images/icon/one-two-three.svg',
    iconMobile: 'assets/images/icon/one-two-three_mobile.svg',
    alt: 'one-two-three',
    router: '/course/course-category',
    description: 'เน้นการพัฒนาทางด้าน ทักษะการคำนวน การวิเคราะห์โจทย์ปัญหา',
  },
  {
    id: 3,
    name: 'ภาษาต่างประเทศ',
    icon: 'assets/images/icon/four-five-six.svg',
    iconMobile: 'assets/images/icon/four-five-six_mobile.svg',
    alt: 'four-five-six',
    router: '/course/course-category',
    description: 'สำหรับผู้ที่ใช้ภาษาใดภาษาหนึ่งหรือมากกว่าเป็นหลัก จะถือว่าภาษาอื่นเป็นภาษาต่างประเทศ',
  },
  {
    id: 4,
    name: 'ติวสอบเอ็นทรานซ์',
    icon: 'assets/images/icon/mortarboard.svg',
    iconMobile: 'assets/images/icon/mortarboard.svg',
    alt: 'mortarboard',
    router: '/course/course-category',
    description: 'หลักสูตรติวเข้มสำหรับนักเรียนชั้นม.6 ที่กำลังมุ่งมั่นที่จะสอบเข้ามหาวิทยาลัย',
  },
  {
    id: 5,
    name: 'ปฐมวัย',
    icon: 'assets/images/icon/cubes.svg',
    iconMobile: 'assets/images/icon/cubes.svg',
    alt: 'cubes',
    router: '/course/course-category',
    description: 'มุ่งให้เด็กมีพัฒนาการด้านร่างกาย อารมณ์ จิตใจ สังคมและสติปัญญา',
  },
];
