import { ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

export class LoadComponent {
  constructor(private cfr: ComponentFactoryResolver) {}

  load(view: ViewContainerRef, component) {
    const factory = this.cfr.resolveComponentFactory(component);
    view.createComponent(factory);
    
  }
}
