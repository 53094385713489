import { Injectable } from '@angular/core';
import { Course, CourseDatatable, CourseListItem, CourseParams } from './course.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CourseState extends EntityState<Course> {
  datatableList: {
    isLoading: boolean;
    error: string;
    data: CourseListItem[];
    totalPage: number;
    total: number;
  };
  params: CourseParams;
  find: {
    data: Course;
    error: string;
  };
}

export function createInitialState(): CourseState {
  return {
    datatableList: {
      isLoading: false,
      error: null,
      data: null,
      totalPage: null,
      total: null,
    },
    params: {
      page: 1,
      order: 'id desc',
      limit: 5,
      keywords: null,
      education_id: null,
      semester_id: null,
      course_category_id: null,
      course_category_ids: null,
    },
    find: {
      data: null,
      error: null,
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'course' })
export class CourseStore extends EntityStore<CourseState, Course> {
  constructor() {
    super(createInitialState());
  }

  clearParams(): void {
    this.update((state) => ({
      ...state,
      params: {
        ...state.params,
        keywords: null,
        education_id: null,
        semester_id: null,
        course_category_ids: null,
        page: 1,
      },
    }));
  }

  clearDatatableList(): void {
    this.update((state) => ({
      ...state,
      datatableList: {
        isLoading: false,
        error: null,
        data: null,
        totalPage: null,
        total: null,
      }
    }))
  }

  pageNext(): void {
    this.update((state) => ({
      ...state,
      params: {
        ...state.params,
        page: state.params.page < state.datatableList.totalPage ? state.params.page + 1 : state.params.page,
      },
    }));
  }

  pagePrevious(): void {
    this.update((state) => ({
      ...state,
      params: {
        ...state.params,
        page: state.params.page !== 1 ? state.params.page - 1 : state.params.page,
      },
    }));
  }

  pageSelected(page: number): void {
    this.update((state) => ({
      ...state,
      params: { ...state.params, page },
    }));
  }

  changeOrderby(): void {
    this.update((state) => {
      let order: string;
      switch (state.params.order.split(' ')[1].toLocaleLowerCase()) {
        case 'asc':
          order = 'id desc';
          break;
        case 'desc':
          order = 'id asc';
          break;
      }
      return {
        ...state,
        params: {
          ...state.params,
          order,
        },
      };
    });
  }

  loadCourseSuccess(response: CourseDatatable): void {
    this.update((state) => ({
      ...state,
      datatableList: {
        ...state.datatableList,
        isLoading: false,
        data: response.data,
        error: null,
        totalPage: response.total_page,
        total: response.total,
      },
    }));
  }
}
